import * as Sentry from "@sentry/browser";
import { Crisp } from "crisp-sdk-web";
import { usePostHog } from "posthog-js/react";
import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";

import { Sidebar } from "@/components/Sidebar/Sidebar";
import { useRootQueryQuery } from "@/generated/graphql";

import { contentStyles, wrapperStyles } from "./AppLayout.css";
import { Header } from "./Header/Header";

export const AppLayout = () => {
  const [{ data }] = useRootQueryQuery();
  const posthog = usePostHog();
  const isIdentified = useRef(false);

  useEffect(() => {
    const user = data?.user;

    if (!user || isIdentified.current) return;

    Crisp.user.setEmail(user?.email ?? "");
    Crisp.session.setData({
      user_id: user?.id,
      email: user?.email,
    });

    Sentry.setUser({ email: user?.email, id: user?.id });

    posthog.identify(user?.id, {
      email: user?.email,
    });

    isIdentified.current = true;
  }, [data, posthog]);

  return (
    <div className={wrapperStyles}>
      <Sidebar
        user={data?.user}
        workspaces={data?.workspaces}
        workspace={data?.workspace}
        notifications={data?.notifications}
      />
      <div className={contentStyles}>
        <Outlet />
      </div>
    </div>
  );
};

AppLayout.Header = Header;
