import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { GithubButton } from "./GithubButton";
import {
  additionalInfoStyles,
  descriptionStyles,
  logoStyles,
  titleStyles,
  wrapperStyles,
} from "./styles.css";

export const SignUp = () => {
  return (
    <>
      <Helmet>
        <title>Sign up - DepsHub</title>
      </Helmet>
      <div className={wrapperStyles}>
        <a href="/">
          <img className={logoStyles} src="logo.svg" />
        </a>
        <div className={titleStyles}>Sign up</div>
        <div className={descriptionStyles}>Create an account to continue.</div>
        <GithubButton title="Sign up with GitHub" />
        <div className={additionalInfoStyles}>
          Already have an account? <Link to="/login">Sign in</Link>
        </div>
      </div>
    </>
  );
};
