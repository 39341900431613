import { Icon } from "@/components/Icon/Icon";

import { iconStyles, wrapperStyles } from "./GithubButton.css";

type GithubButtonProps = {
  title: string;
};

export const GithubButton = ({ title }: GithubButtonProps) => {
  return (
    <a
      className={wrapperStyles}
      href={"https://" + import.meta.env.VITE_API_URL + "/auth/github/login"}
    >
      <Icon.Github className={iconStyles} /> {title}
    </a>
  );
};
