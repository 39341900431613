import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { GithubButton } from "./GithubButton";
import {
  additionalInfoStyles,
  descriptionStyles,
  logoStyles,
  titleStyles,
  wrapperStyles,
} from "./styles.css";

export const SignIn = () => {
  return (
    <>
      <Helmet>
        <title>Sign in - DepsHub</title>
      </Helmet>
      <div className={wrapperStyles}>
        <a href="/">
          <img className={logoStyles} src="logo.svg" />
        </a>
        <div className={titleStyles}>Sign in</div>
        <div className={descriptionStyles}>
          Log in to your account to continue.
        </div>
        <GithubButton title="Sign in with GitHub" />
        <div className={additionalInfoStyles}>
          Don't have an account? <Link to="/register">Sign up</Link>
        </div>
      </div>
    </>
  );
};
