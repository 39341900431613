import Cookies from "js-cookie";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type AuthContextValue = {
  isAuthenticated: boolean | null;
  logout: () => void;
};

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: null,
  logout: () => null,
});

export function useAuth() {
  return useContext(AuthContext);
}

type AuthProviderProps = {
  children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] =
    useState<AuthContextValue["isAuthenticated"]>(null);
  const session = Cookies.get("i");

  useEffect(() => {
    if (session) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [session]);

  // Function to simulate a logout
  const logout = useCallback(async () => {
    setIsAuthenticated(false);
    fetch(`https://${import.meta.env.VITE_API_URL}/auth/logout`, {
      method: "POST",
      credentials: "include",
    }).then(() => window.location.reload());
  }, []);

  // Provide the authentication state and functions to the context
  const authContextValue: AuthContextValue = useMemo(
    () => ({
      isAuthenticated,
      logout,
    }),
    [isAuthenticated, logout]
  );

  if (isAuthenticated === null) {
    return null;
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {" "}
      {children}
    </AuthContext.Provider>
  );
}
